<template>
  <div class="main">
    <div class="operator">
      <!-- 选择器 -->
      <div class="select_box">
        <!-- 运营商名称 -->
        <el-input
          class="ipt_box mr12 mt12"
          v-model="name_operator"
          placeholder="请输入运营商名称"
        ></el-input>

        <!-- 联系人 -->
        <el-input
          class="ipt_box mr12 mt12"
          v-model="name_chief"
          placeholder="请输入联系人"
        ></el-input>

        <!-- 联系方式 -->
        <el-input
          class="ipt_box mr12 mt12"
          v-model="tel"
          placeholder="请输入联系方式"
        ></el-input>

        <div class="mr12 mt12">
          <el-button
            type="primary"
            size="small"
            @click="search"
          >查询</el-button>
          <el-button
            size="small"
            type="primary"
            plain
            @click="reset"
          >重置</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="table_box">
        <div class="add_operator">
          <i
            class="el-icon-plus"
            color="#2a47b0"
            @click="addOperator"
          ></i>
          <span @click="addOperator">添加运营商</span>
        </div>

        <el-table
          :data="operatorList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb',
            height: '40px'
          }"
          :stripe="true"
          size="small"
        >
          <el-table-column
            prop="name_operator"
            label="运营商名称"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="name_chief"
            label="联系人"
            width="200"
          ></el-table-column>

          <el-table-column
            prop="tel"
            label="联系方式"
            width="200"
          ></el-table-column>

          <el-table-column label="项目列表">
            <template v-slot="scope">
              <el-tag
                size="small"
                style="margin-right: 6px"
                v-for="ite in scope.row.project_list"
                :key="ite.id_project"
              >{{ ite.name_org }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200"
          >
            <template v-slot="scope">
              <span
                class="edit_box cursor"
                @click="editOperator(scope.row)"
              >
                <i class="el-icon-edit-outline"></i>
                <span class="edit_table">修改</span>
              </span>

              <span
                class="del_box cursor"
                @click="delOperator(scope.row)"
              >
                <i class="el-icon-delete"></i>
                <span class="edit_table">删除</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页-->
      <div class="pagination_box">
        <el-pagination
          @size-change="sizeChange"
          @current-change="CurrentChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <ChangeOperator
      ref="changeOperatorRef"
      @refresh="getOperator"
    />
  </div>
</template>

<script>
import { GetOperatorList, DelOperator } from '@/api'
import ChangeOperator from './dialog/change_operator.vue'

export default {
  name: 'operator',
  components: { ChangeOperator },
  data() {
    return {
      page_id: 0,
      page_num: 20,
      total: 0,
      // 运营商名称
      name_operator: '',
      // 联系人
      name_chief: '',
      // 联系方式
      tel: '',
      // 运营商列表
      operatorList: []
    }
  },
  methods: {
    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s
      this.page_id = 0
      this.getOperator()
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p - 1
      this.getOperator()
    },

    // 查询
    search() {
      this.page_id = 0
      this.getOperator()
    },

    // 重置
    reset() {
      this.page_id = 0
      this.name_operator = this.name_chief = this.tel = ''
      this.getOperator()
    },

    // 获取参数
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }

      if (this.name_operator.trim()) {
        params.name_operator = this.name_operator.trim()
      }

      if (this.name_chief.trim()) {
        params.name_chief = this.name_chief.trim()
      }

      if (this.tel.trim()) {
        params.tel = this.tel.trim()
      }

      return params
    },

    // 这里需要同时获取 运营商列表 和 系统列表
    async getOperator() {
      const params = this.getParams()
      const { ret, data, msg } = await GetOperatorList(params)
      if (ret !== 0) {
        return this.$message.error(msg || '获取运营商列表失败')
      }
      this.total = data.cnt_all
      this.operatorList = data.data
    },

    // 添加运营商
    addOperator() {
      this.$refs.changeOperatorRef.add()
    },

    // 修改运营商
    editOperator(row) {
      this.$refs.changeOperatorRef.edit(row)
    },

    // 删除运营商
    async delOperator(row) {
      const result = await this.$confirm('是否确定删除该运营商?', '删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => 'cancel')

      if (result === 'cancel') return

      const { ret, data, msg } = await DelOperator({
        id_operator: row.id_operator
      })
      if (ret !== 0) {
        return this.$message.error(msg || '删除运营商失败')
      }
      this.$message.success('删除运营商成功!')
      this.getOperator()
    }
  },

  created() {
    this.getOperator()
  }
}
</script>

<style lang='scss' scoped>
.operator {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: $radius;
  padding: 12px;
}

.table_box {
  padding: 0 12px;
  margin-top: 12px;

  .add_operator {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $theme;
    cursor: pointer;

    i {
      font-size: 14px;
    }

    span {
      margin-left: 4px;
      font-size: 14px;
    }
  }

  .edit_box {
    margin: 0 5px;

    i {
      color: #2a47b0;
    }
  }

  .del_box {
    margin: 0 5px;

    i {
      color: red;
    }

    span {
      color: red;
    }
  }
}
</style>
