<template>
  <el-dialog
    :title="isEdit ? '修改运营商' : '添加运营商'"
    :visible.sync="dialogVisible"
    width="678px"
    :close-on-click-modal="false"
    @closed="resetForm"
  >
    <div style="padding: 0 24px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商名称：" prop="name_operator">
              <el-input
                v-model.trim="ruleForm.name_operator"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系人：" prop="name_chief">
              <el-input
                v-model.trim="ruleForm.name_chief"
                maxlength="4"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系方式：" prop="tel">
              <el-input v-model.trim="ruleForm.tel" maxlength="11"></el-input>
            </el-form-item>
          </el-col>

          <template v-if="!isEdit">
            <el-col :span="12">
              <el-form-item label="用户名：" prop="name_user">
                <el-input
                  v-model.trim="ruleForm.name_user"
                  maxlength="24"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="密码：" prop="pwd">
                <el-input
                  v-model.trim="ruleForm.pwd"
                  maxlength="16"
                  type="password"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="确认密码：" prop="confirm_pwd">
                <el-input
                  v-model.trim="ruleForm.confirm_pwd"
                  maxlength="16"
                  type="password"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddOperator, EditOperator } from '@/api'
import MD5 from 'md5'

const checkTel = (rule, value, callback) => {
  const reg = /^1[0-9]{10}$/
  if (!reg.test(value)) {
    callback(new Error('联系方式输入错误'))
    return
  }
  callback()
}

const checkNameUser = (rule, value, cb) => {
  const reg = /^[0-9a-zA-Z]{1,24}$/
  const v = value.trim()
  if (!reg.test(v)) {
    cb(new Error('用户名只能输入 0-9 a-z A-Z'))
  } else {
    cb()
  }
}

export default {
  data() {
    return {
      // 是否显示对话框
      dialogVisible: false,
      // 是否是编辑
      isEdit: false,
      // 表单
      ruleForm: {
        // 运营商id
        id_operator: '',
        // 运营商名称
        name_operator: '',
        // 联系人
        name_chief: '',
        // 联系方式
        tel: '',
        // 用户名
        name_user: '',
        // 密码
        pwd: '',
        // 确定密码
        confirm_pwd: ''
      },
      // 规则
      rules: {
        name_operator: [
          {
            required: true,
            message: '请输入运营商',
            trigger: 'blur'
          }
        ],
        name_chief: [
          {
            required: true,
            message: '请输入联系人',
            trigger: 'blur'
          }
        ],

        tel: [
          {
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          },
          {
            validator: checkTel,
            trigger: 'blur'
          }
        ],
        name_user: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { validator: checkNameUser, trigger: 'blur' }
        ],
        pwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        confirm_pwd: [
          { required: true, message: '请输入确认密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 添加
    add() {
      this.isEdit = false
      this.dialogVisible = true
    },

    // 修改
    edit(row) {
      this.isEdit = true
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.id_operator = row.id_operator
        this.ruleForm.name_operator = row.name_operator
        this.ruleForm.name_chief = row.name_chief
        this.ruleForm.tel = row.tel
      })
    },

    // 确定
    confirm() {
      this.$refs.ruleForm.validate(_ => {
        if (!_) return
        if (this.isEdit) {
          this.saveEdit()
        } else {
          this.addOperator()
        }
      })
    },

    // 保存修改的内容
    async saveEdit() {
      const { ret, data, msg } = await EditOperator(this.ruleForm)
      if (ret !== 0) {
        return this.$message.error(msg || '修改运营商失败!')
      }
      this.$message.success('修改运营商成功')
      this.$emit('refresh')
      this.dialogVisible = false
    },

    // 添加运营商
    async addOperator() {
      if (this.ruleForm.pwd !== this.ruleForm.confirm_pwd) {
        this.$message.warning('两次输入的密码不一致!')
        return
      }
      const params = { ...this.ruleForm }
      delete params.pwd
      params.pwd = MD5(this.ruleForm.pwd)
      delete params.id_operator
      const { ret, data, msg } = await AddOperator(params)
      if (ret !== 0) {
        return this.$message.error(msg || '添加运营商失败!')
      }
      this.$message.success('添加运营商成功')
      this.$emit('refresh')
      this.dialogVisible = false
    },

    // 重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
